<template>
	<b-card
		v-if="statistics"
		no-body
		class="card-statistics"
	>
			<b-card-body class="statistics-body">
				<b-row>
					<b-col
						xl="12"
						sm="12"
					>
            <h3>Bienvenido!</h3>
            <div class="mt-2">
            ¡Bienvenido/a de vuelta!
            </div>
					</b-col>
				</b-row>
			</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard, BCardBody, BRow, BCol
} from 'bootstrap-vue'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
	},
	data() {
		return {
			showLoading: false,
			statistics: []
		}
	},
	created() {

	},
	methods: {

	}
}
</script>
