<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col
				xl="12"
				md="6"
			>
				<statistics/>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'

import Statistics from './components/Statistics.vue'

export default {
	components: {
		BRow,
		BCol,

		Statistics,
	},
	data() {
		return {
			data: {
				companyTable: [
					{
						avatarImg: require('@/assets/images/icons/toolbox.svg'),
						title: 'Carmín',
						subtitle: 'Torres Montanez',
						avatarIcon: 'MonitorIcon',
						avatarColor: 'light-success',
						avatarTitle: 'Disponible',
						viewTitle: '5',
						viewsub: 'Aquesta setmana',
						revenue: '891.2',
						sales: '68',
						loss: true,
					},
					{
						avatarImg: require('@/assets/images/icons/parachute.svg'),
						title: 'Abel',
						subtitle: 'Costabella Yagoubi',
						avatarIcon: 'CoffeeIcon',
						avatarColor: 'light-success',
						avatarTitle: 'Disponible',
						viewTitle: '10',
						viewsub: 'Aquesta setmana',
						revenue: '668.51',
						sales: '97',
						loss: false,
					},
					{
						avatarImg: require('@/assets/images/icons/brush.svg'),
						title: 'Cristina',
						subtitle: 'Belkassmi Lerma',
						avatarIcon: 'WatchIcon',
						avatarColor: 'light-success',
						avatarTitle: 'Disponible',
						viewTitle: '2',
						viewsub: 'Aquesta setmana',
						revenue: '522.29',
						sales: '62',
						loss: false,
					},
					{
						avatarImg: require('@/assets/images/icons/star.svg'),
						title: 'Roman',
						subtitle: 'Sagardui Manias',
						avatarIcon: 'MonitorIcon',
						avatarColor: 'light-danger',
						avatarTitle: 'No Disponible',
						viewTitle: '9',
						viewsub: 'Aquesta setmana',
						revenue: '291.01',
						sales: '88',
						loss: false,
					},
					{
						avatarImg: require('@/assets/images/icons/book.svg'),
						title: 'Raimondo',
						subtitle: 'Pesce Biserov',
						avatarIcon: 'CoffeeIcon',
						avatarColor: 'light-success',
						avatarTitle: 'Disponible',
						viewTitle: '1',
						viewsub: 'Aquesta setmana',
						revenue: '783.93',
						sales: '16',
						loss: true,
					},
					{
						avatarImg: require('@/assets/images/icons/rocket.svg'),
						title: 'Clemente',
						subtitle: 'Priboi Huayta',
						avatarIcon: 'WatchIcon',
						avatarColor: 'light-danger',
						avatarTitle: 'No Disponible',
						viewTitle: '7',
						viewsub: 'Aquesta setmana',
						revenue: '780.05',
						sales: '78',
						loss: false,
					},
					{
						avatarImg: require('@/assets/images/icons/speaker.svg'),
						title: 'Daniella',
						subtitle: 'Iglesias Badita',
						avatarIcon: 'WatchIcon',
						avatarColor: 'light-danger',
						avatarTitle: 'No Disponible',
						viewTitle: '3',
						viewsub: 'Aquesta setmana',
						revenue: '531.49',
						sales: '42',
						loss: false,
					},
				],
			}
		}
	},
	created() {

	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
